import { useQuery } from '@tanstack/react-query';
import { loggedInUser, useOrgService } from 'hooks';
import { IRegion } from 'types';

interface RegionCache {
  regions: IRegion[];
  isRegionsLoading: boolean;
}

export const useRegionsCache = (): RegionCache => {
  const orgService = useOrgService();
  const { isLoggedIn } = loggedInUser.useController();

  const regionQuery = useQuery({
    queryKey: ['regions'],
    queryFn: () => orgService.getSollisRegions(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const regions = regionQuery.data ? regionQuery.data : [];
  const isRegionsLoading = regionQuery.isLoading;

  return {
    regions,
    isRegionsLoading
  };
};
